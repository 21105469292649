import React from "react"
import { Link } from "gatsby"
import "./institutes.css"

const FlexItem = ({ link, name, address, phone, fax }) => {
  return (
    <div className="inst-card">
      <div className="card-ui">
        <Link to={link} className="title-link row">
          {name}
        </Link>
        <div className="row">
          <div className="col">
            <h6 className="tt tt-mod">{address[0]}</h6>
            <div className="address">{address[1]}</div>
          </div>

          <div className="col">
            <div className="phone">
              <h6 className="tt">Telephone</h6>
              {phone}
            </div>
            <div className="fax">
              <h6 className="tt">Fax</h6>
              {fax}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Institutes = ({ inst }) => {
  return (
    <section className="flex-items">
      {inst.map((item, id) => (
        <FlexItem {...item} key={id} />
      ))}
    </section>
  )
}

export default Institutes
