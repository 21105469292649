import React from "react"
import "./page-title.css"

const PageTitle = ({ hTitle }) => {
  return (
    <section className="section-mod" id="page-title">
      <h1 className="page-title">{hTitle}</h1>
    </section>
  )
}

export default PageTitle
