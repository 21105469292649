import React from "react"
import "./g-map.css"

const Gmap = () => {
  return (
    <section className="g-map-holder">
      <iframe
        title="google map"
        src="https://www.google.com/maps/d/embed?mid=1q2zqNvcwxElYC3zEiJU5EWGLoyzi8k3B"
        width="100%"
        height="100%"
      ></iframe>
    </section>
  )
}

export default Gmap
