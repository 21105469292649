import React from "react"
import "./contacts-list.css"

const importPartners = r => {
  return r.keys().map(r)
}

const hiddenMail = email => {
  return (window.location.href =
    "mailto:" + email[0] + "@" + email[1] + "." + email[2])
}

const AddCard = ({ img, name, subname, email, link, marking, markdown }) => {
  let temp = subname.split(",").map(sp => sp)
  return (
    <div className="add-card" id={marking}>
      <div className="img-wrapper">
        <img src={img.default} alt={`${marking} - contact info`} />
      </div>
      <div className="title">{name}</div>
      <div className="subtitle">
        {temp[0]}
        <br />
        {temp[1]}
      </div>
      <div className="mail">
        <a
          href="#"
          className="crp-mail"
          data-name={email[0]}
          data-domain={email[1]}
          data-tld={email[2]}
          onClick={hiddenMail}
          aria-label="Dest crypt"
        />
      </div>
      <div className="link">
        <a target="_blank" href={link}>
          {markdown.cta}
        </a>
      </div>
      <div className="gatsby-link-tag" id={`${marking}-mod`} />
    </div>
  )
}

const ContactsList = ({ contacts, markdown }) => {
  const images = importPartners(
    require.context("../../assets/profile-images", false, /\.(png|jpe?g|svg)$/)
  )

  const getImage = (images, kw) => {
    return images.filter(image => image.default.search(kw) !== -1)[0]
  }

  return (
    <section className="flex-box">
      {contacts.map((contact, id) => (
        <AddCard
          key={id}
          name={contact.name}
          subname={contact.subname}
          email={contact.email}
          link={contact.link}
          img={getImage(images, contact.imgKw)}
          marking={contact.imgKw}
          markdown={markdown}
        />
      ))}
    </section>
  )
}

export default ContactsList
