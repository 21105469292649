import React from "react"
import { Link } from "gatsby"

export const pageContent404 = {
  en: {
    h: "This page does not exist",
    p: (
      <>
        Unfortunatlly this route does not exist. <br />
        Get to the main page from{" "}
        <Link style={{ color: "#1501FF" }} to="/">
          here
        </Link>
        .
      </>
    ),
    button: "Go Back",
  },
  rs: { h: "Ova stranica nije postojeća", p: <></>, button: "Natrag" },
}

export const pageContentActivities = {
  en: {
    h: "Activities",
    labels: ["all categories", "news", "event", "promotion"],
  },
  rs: {
    h: "Aktivnosti",
    labels: ["sve kategorije", "novosti", "dogadjaj", "promocija"],
  },
}

export const pageContentContact = {
  en: {
    h: "Contact Information",
    institutes: [
      {
        link: "https://www.imgge.bg.ac.rs/",
        name:
          "INSTITUTE OF MOLECULAR GENETICS AND GENETIC ENGINEERING, University of Belgrade",
        address: ["Vojvode Stepe 444a", "11042 Belgrade"],
        phone: "+381 63 89 90 900",
        fax: "+381 63 89 90 900",
      },
      {
        link: "www.imsi.bg.ac.rs",
        name:
          "INSTITUTE FOR MULTIDISCIPLINARY RESEARCH, University of Belgrade",
        address: ["Kneza Višeslava 1", "11030 Belgrade"],
        phone: "+381 11 3555 258",
        fax: "+381 11 3055 289",
      },
      {
        link: "http://www.chem.bg.ac.rs/",
        name: "FACULTY OF CHEMISTRY, University of Belgrade",
        address: ["Studentski trg 12-16", "11158 Belgrade"],
        phone: "+381 11 3282 111",
        fax: "+381 11 3282 111",
      },
      {
        link: "https://singidunum.ac.rs/",
        name: "SINGIDUNUM, University of Belgrade",
        address: ["Danijelova 32", "11000 Belgrade"],
        phone: "+381 11 3094 094",
        fax: "+381 11 3094 094",
      },
    ],
    contacts: [
      {
        imgKw: "jelena",
        name: "Dr. Jelena Radosavljević",
        subname: " , Assistant Professor",
        email: ["radosavljevic", "chem.bg.ac", "rs"],
        link: "https://www.chem.bg.ac.rs/osoblje/189-l.html",
      },
      {
        imgKw: "marija",
        name: "Dr. Marija Vidović",
        subname: "PI, Assistant Research Professor",
        email: ["mvidovic", "imgge.bg.ac.", "rs"],
        link: "https://imgge.bg.ac.rs/index.php/yu/marija-vidovi%C4%87",
      },
      {
        imgKw: "milena",
        name: "Dr. Milena G. Rikalović",
        subname: " ,Assistent Professor - Research Associate",
        email: ["mrikalovic", "singidunum.ac", "rs"],
        link: "https://singidunum.ac.rs/profile/mrikalovic",
      },
      {
        imgKw: "natasa",
        name: "Dr. Nataša Kilibarda",
        subname: ",Assistant Professor",
        email: ["nkilibarda", "singidunum.ac", "rs"],
        link: "https://singidunum.ac.rs/profile/nkilibarda",
      },
      {
        imgKw: "sonja",
        name: "Dr. Sonja Milić Komić",
        subname: ",Assistant Research Professor",
        email: ["sonjamilic", "imsi", "rs"],
        link: "http://www.imsi.bg.ac.rs/cv_template/?id_zap=132",
      },
      {
        imgKw: "strahinja",
        name: "Strahinja Stevanović, M.Sc.",
        subname: ",Research Associate",
        email: ["sstevanovic", "imggi.bg.ac", "rs"],
        link: "https://imgge.bg.ac.rs/yu/strahinja-stevanovi%C4%87",
      },
      {
        imgKw: "sonjav",
        name: "Dr. Sonja Veljović Jovanović",
        subname: ",Assistent Research Professor",
        email: ["sonjavel", "imsi.bg.ac", "rs"],
        link: "http://www.imsi.bg.ac.rs/cv_template/?id_zap=048",
      },
    ],
    cta: "more info",
  },
  rs: {
    h: "Kontakt Informacije",
    institutes: [
      {
        link: "https://www.imgge.bg.ac.rs/",
        name:
          "INSTITUT ZA MOLEKULARNU GENETIKU I GENETIČKO INŽENJERSTVO, Univerzitet u Beogradu",
        address: ["Vojvode Stepe 444a", "11042 Beograd"],
        phone: "+381 63 89 90 900",
        fax: "+381 63 89 90 900",
      },
      {
        link: "www.imsi.bg.ac.rs",
        name:
          "INSTITUT ZA MULTIDISCIPLINARNA ISTRAŽIVANJA, Univerzitet u Beogradu",
        address: ["Kneza Višeslava 1", "11030 Beograd"],
        phone: "+381 11 3555 258",
        fax: "+381 11 3055 289",
      },
      {
        link: "http://www.chem.bg.ac.rs/",
        name: "HEMIJSKI FAKULTET, Univerzitet u Beogradu",
        address: ["Studentski trg 12-16", "11158 Beograd"],
        phone: "+381 11 3282 111",
        fax: "+381 11 3282 111",
      },
      {
        link: "https://singidunum.ac.rs/",
        name: "SINGIDUNUM, Univerzitet u Beogradu",
        address: ["Danijelova 32", "11000 Beograd"],
        phone: "+381 11 3094 094",
        fax: "+381 11 3094 094",
      },
    ],
    contacts: [
      {
        imgKw: "jelena",
        name: "dr Jelena Radosavljević",
        subname: ", docent",
        email: ["radosavljevic", "chem.bg.ac", "rs"],
        link: "https://www.chem.bg.ac.rs/osoblje/189-l.html",
      },
      {
        imgKw: "marija",
        name: "dr Marija Vidović",
        subname: "PI, naučni saradnik",
        email: ["mvidovic", "imgge.bg.ac.", "rs"],
        link: "https://imgge.bg.ac.rs/index.php/yu/marija-vidovi%C4%87",
      },
      {
        imgKw: "milena",
        name: "dr Milena G. Rikalović",
        subname: ", docent - naučni saradnik",
        email: ["mrikalovic", "singidunum.ac", "rs"],
        link: "https://singidunum.ac.rs/profile/mrikalovic",
      },
      {
        imgKw: "natasa",
        name: "dr Nataša Kilibarda",
        subname: ", docent",
        email: ["nkilibarda", "singidunum.ac", "rs"],
        link: "https://singidunum.ac.rs/profile/nkilibarda",
      },
      {
        imgKw: "sonja",
        name: "dr Sonja Milić Komić",
        subname: ",naučni saradnik",
        email: ["sonjamilic", "imsi", "rs"],
        link: "http://www.imsi.bg.ac.rs/cv_template/?id_zap=132",
      },
      {
        imgKw: "strahinja",
        name: "M.Sc. Strahinja Stevanović",
        subname: ", istraživač saradnik",
        email: ["sstevanovic", "imggi.bg.ac", "rs"],
        link: "https://imgge.bg.ac.rs/yu/strahinja-stevanovi%C4%87",
      },
      {
        imgKw: "sonjav",
        name: "dr Sonja Veljović Jovanović",
        subname: ", naučni savetnik",
        email: ["sonjavel", "imsi.bg.ac", "rs"],
        link: "http://www.imsi.bg.ac.rs/cv_template/?id_zap=048",
      },
    ],
    cta: "više informacija",
  },
}

export const pageContentGallery = {
  en: {},
  rs: {},
}

export const pageContentHome = {
  en: {
    pi: {
      h: ["project", "idea"],
      ph: (
        <>
          Late Embryogenesis Abundant Proteins: Structural Characterization and
          Interaction with &alpha;-Synuclein is a project idea to develope a new
          strategy against amyloid aggregation by employing the constituents of
          molecular mechanisms underlying the phenomenon of desiccation
          tolerance: Late Embryogenesis Abundant Proteins which may have role in
          stabilisation of the correct structure of proteins and cell membranes.
        </>
      ),
    },
    pa: {
      h: ["project", "aims"],
      aims: [
        {
          title: "Producing new recombinant LEAPs",
          cnt: (
            <>
              discovering potential of LEAPs originated from the serbian phoenix
              flower - <span>Ramonda serbica</span>
            </>
          ),
          year: 2020,
        },
        {
          title: "Conditioning aggregation",
          cnt:
            "of aggregation-prone alpha synuclein and conditioning of the aggregation pathway",
          year: 2021,
        },
        {
          title: "Ameliorating the effect",
          cnt:
            "of dehydration-induced damage in liposomes simulating biological membranes",
          year: 2022,
        },
        {
          title: "Delivering application",
          cnt:
            "from biotechnology to agriculture (new strategies for crops adaptation to arid climate)",
          year: 2023,
        },
      ],
    },
    pn: {
      h: "Promising Drug Discovery strategies:",
      sh: "From the ancient resurrection endemic plant to a better medicine",
      ph: (
        <>
          The most common neurodegenerative disorders,{" "}
          <span>Alzheimer’s and Parkinson's diseases</span> are tightly
          associated with the aggregation of alpha-synuclein into insoluble,
          amyloid fibrils. Despite numerous contemporary studies focused either
          on identifying small molecules that could interfere with the
          alpha-synuclein aggregation or on immunotherapies towards amyloid
          fibrils, the cure for these diseases has not been found yet. A key
          objective for discovering the effective treatment is to understand the
          aggregation pathway of alpha-synuclein and its normal and abnormal
          functions.
        </>
      ),
      bq:
        "Reduction of the aggregation of α-Synuclein by desiccation-related LEA proteins would promote development of the novel strategies to treat neurodegenerative disorders.",
    },
    pf: {
      ph:
        "LEAPSyn-Sci is financed by the Science Fund, Republic of Serbia 2019.",
      fundIcon: "en",
      cta: ["Find out more", "about", "PROMIS initiative"],
    },
    about: {
      h: ["project", "team"],
      researchers: [
        {
          name: "Dr. Marija Vidović ",
          image: "marija",
          subname:
            "Assistant Research Professor, biochemist and plant physiologist",
          exp:
            "Proteomics - metabolomics - MALDI MS imaging and UPLC-ESI-Q/TOF ( method development )",
          fields: [
            "Plant abiotic stress",
            "Metabolic fluxes",
            "UVB induced polyphenols",
            "Protein structure / function relationships",
          ],
          customIcon: 5,
          orcidId: "0000-0001-5855-7192",
          researcherId: "N/a",
          scopus: "37040861200",
        },
        {
          name: "Dr. Jelena Radosavljević",
          image: "jelena",
          subname: "Assistant Professor, biochemist",
          exp:
            "Protein characterization - recombinant protein production - site-directed mutagenesis - proteomics",
          fields: [
            "Food protein allergenicity",
            "Modifications of food proteins",
            "Protein structure / function relationship",
          ],
          customIcon: 3,
          orcidId: "0000-0002-6123-5474",
          researcherId: "O-9669-2016",
          scopus: "35072032200",
        },
        {
          name: "Dr. Sonja Milić Komić",
          image: "sonja",
          subname: "Assistant Research Professor, chemist",
          exp: "Antioxidant capacity - EPR - metabolomics",
          fields: [
            "Reactive oxygen species",
            "Abiotic plant stress",
            "Antioxidative activity of amino acids",
          ],
          customIcon: 2,
          orcidId: "0000-0002-4832-8668",
          researcherId: "N/A",
          scopus: "54682186600",
        },
        {
          name: "Dr. Milena G. Rikalović",
          image: "milena",
          subname:
            "Assistant Professor - Research Associate, biochemist and microbiologist",
          exp:
            "Applied microbiology - environmental isolates characterisation - fermentations - biotechnology",
          fields: [
            "Production of secondary microbial metabolites",
            "Microbe – environment interactions",
            "Biomarkers",
          ],
          customIcon: 1,
          orcidId: "0000-0002-1809-5461",
          researcherId: "N/a",
          scopus: "N/a",
        },
        {
          name: "Dr. Nataša Kilibarda",
          image: "natasa",
          subname: "Assistant Professor, food microbiologist",
          exp: "Determination of various microbial species",
          fields: ["Biotechnology", "Applied microbiology", "Fermentations"],
          customIcon: 1,
          orcidId: "0000-0002-2967-3749",
          researcherId: "N/a",
          scopus: "28167570100",
        },
        {
          name: "Msc. Strahinja Stevanović",
          image: "strahinja",
          subname: "Junior researcher, chemist",
          exp: "Proteomics - chemoinformatics - bioinformatics",
          fields: [
            "Intrinsically disordered proteins",
            "LEA proteins",
            "Protein structure / function relationship",
          ],
          customIcon: 4,
          orcidId: "0000-0002-9173-985X",
          researcherId: "N/a",
          scopus: "57189646996",
        },
        {
          name: "Dr. Sonja Veljović Jovanović",
          image: "sonjav",
          subname: "Research Professor, plant biologist",
          exp: "Photosyntesis - antioxidative enzymes - EPR measurements",
          fields: [
            "Antioxidant metabolism – effect of biotic and abiotic stresses",
            "ROS signalling",
            "Resurrection plants",
          ],
          customIcon: 5,
          orcidId: "0000-0001-5536-7529",
          researcherId: "N/a",
          scopus: "6602549178",
        },
      ],
      cta: ["Researcher IDs", "Get in touch"],
    },
  },
  rs: {
    pi: {
      h: ["Projektna", "ideja"],
      ph: (
        <>
          Proteini zastupljeni u kasnoj emrbiogenezi: Strukturna karakterizacija
          i interakcija sa &alpha;-Sinukleinom ima za svoj cilj razvoj novih
          strategija za borbu protiv amiloidne agregacije primenom konstituenata
          koji učestvuju u molekuslkih mehanizama fenomena tolerancije na
          desikaciju: Proteini zastupljeni u kasnoj embriogenezi (LEAP) koji
          imaju ulogu u stabilizaciji funkcionalnih struktura proteina i
          ćelijskih membrana.
        </>
      ),
    },
    pa: {
      h: ["Projektni", "ciljevi"],
      aims: [
        {
          title: "Proizvodnja novih LEAPs",
          cnt: (
            <>
              otkrivanje potencijala LEAPs koji vode poreklo iz srpskog feniks
              cveta -<span>Ramonda serbica</span>
            </>
          ),
          year: 2020,
        },
        {
          title: "Uslovljavanje agregacije",
          cnt:
            "alpha sinukleina na osnovu uticaja na agregacione biološke puteve",
          year: 2021,
        },
        {
          title: "Ublažavanje efekata",
          cnt:
            "dehidratacijom-izazvanih oštećenja lipozoma, kao modela biološke membrane",
          year: 2022,
        },
        {
          title: "Adekvatna primena",
          cnt:
            "od biotehnologije do poljoprivrede (nove strategije za prilagođavanje useva sušnoj klimi)",
          year: 2023,
        },
      ],
    },
    pn: {
      h: "Potencijalne strategije razvoja leka:",
      sh: "Od drevne endemske biljke do efikasnog medikamenta",
      ph: (
        <>
          Najzastupljenija neurodegenerativna oboljenja,{" "}
          <span>Alchajmerova i Parkinsonova bolest</span>, su u vezi sa
          mehanizmom agregacije alfa-sinukleina u oblik nerastvornih, amilodnih
          vlakana. Bez obzira na brojne savremene studije koje imaju za cilj
          indentifikaciju malih molekula koji bi sprečili agregaciju
          alfa-sinukleina, ili imunoterapiju amiloidnih vlakana, jedinstveni lek
          za gorespomenute bolesti nije definisan. Ključni zadatak za oktrivanje
          efikasne terapije jesto razumevanje agregacionih puteva
          alfa-sinukleina i njegove bazične i izmenjene biološke funkcije.
        </>
      ),
      bq:
        "Redukcija agregacije α-Sinukleina pomoću LEA proteina doprinosi razvoju novih metoda u lečenju neurodegenerativnih oboljenja.",
    },
    pf: {
      ph:
        "LEAPSyn-Sci je finansiran od strane Fonda za Nauku, Republika Srbija 2019.",
      fundIcon: "rs",
      cta: ["Saznajte više", "o", "PROMIS programu"],
    },
    about: {
      h: ["projektni", "tim"],
      researchers: [
        {
          name: "dr Marija Vidović ",
          image: "marija",
          subname: "Naučni saradnik, biohemičar i fiziolog biljaka",
          exp:
            "Proteomika - metabolomika - MALDI MS imaging i UPLC-ESI-Q/TOF ( razvoj metode )",
          fields: [
            "Abiotski stres biljaka",
            "Metabolički fluksevi",
            "UVB indukovani polifenoli",
            "Odnos strukture i funkcije proteina",
          ],
          customIcon: 5,
          orcidId: "0000-0001-5855-7192",
          researcherId: "N/a",
          scopus: "37040861200",
        },
        {
          name: "dr Jelena Radosavljević",
          image: "jelena",
          subname: "Docent, doktor biohemijskih nauka",
          exp:
            "Karakterizacija proteina - proizvodnja rekombinantnih proteina - mesto-specifična mutageneza - proteomika",
          fields: [
            "Alergenost proteina hrane",
            "Modifikacije proteina hrane",
            "Odnos strukture i funkcije proteina",
          ],
          customIcon: 3,
          orcidId: "0000-0002-6123-5474",
          researcherId: "O-9669-2016",
          scopus: "35072032200",
        },
        {
          name: "dr Sonja Milić Komić",
          image: "sonja",
          subname: "Naučni saradnik, hemičar",
          exp: "Antioksidativni kapacitet - EPR - metabolomika",
          fields: [
            "Reaktivne kiseonične vrsta",
            "Abiotski stres u biljkama",
            "Antioksidaciona svojstva aminokiselina",
          ],
          customIcon: 2,
          orcidId: "0000-0002-4832-8668",
          researcherId: "N/A",
          scopus: "54682186600",
        },
        {
          name: "dr Milena G. Rikalović",
          image: "milena",
          subname: "Docent - naučni saradnik, biohemičar i mikrobiolog",
          exp:
            "Primenjena mikrobiologija - karakterizacija izolata poreklom iz specifične sredine - fermentacija - biotehnologija",
          fields: [
            "Produkcija sekundarnih mikrobnih metabolita",
            "Interakcija mikroorganizama i okruženja",
            "Biomarkeri",
          ],
          customIcon: 1,
          orcidId: "0000-0002-1809-5461",
          researcherId: "N/a",
          scopus: "N/a",
        },
        {
          name: "dr Nataša Kilibarda",
          image: "natasa",
          subname: "Docent, doktor medicinskih nauka - veterinarske medicine",
          exp: "izolacija i identifikacija različitih mikrobioloških vrsta",
          fields: [
            "Biotehnologija",
            "Primenjena mikrobiologija",
            "Fermentacioni procesi",
          ],
          customIcon: 1,
          orcidId: "0000-0002-2967-3749",
          researcherId: "N/a",
          scopus: "28167570100",
        },
        {
          name: "msc Strahinja Stevanović",
          image: "strahinja",
          subname: "Istraživač saradnik, hemičar",
          exp: "Proteomika - heminformatika - bioinformatika",
          fields: [
            "Nativno neuređeni proteini",
            "LEA proteini",
            "Odnos strukture i funkcije proteina",
          ],
          customIcon: 4,
          orcidId: "0000-0002-9173-985X",
          researcherId: "N/a",
          scopus: "57189646996",
        },
        {
          name: "dr Sonja Veljović Jovanović",
          image: "sonjav",
          subname: "Naučni savetnik, fiziolog biljaka",
          exp: "Fotosinteza - antioksidativni enzimi - EPR merenja",
          fields: [
            "Antioksidativni metabolizam - uticaj biotskog i abioskog stresa",
            "ROS signalni putevi",
            "Biljke vaskrsnice",
          ],
          customIcon: 5,
          orcidId: "0000-0001-5536-7529",
          researcherId: "N/a",
          scopus: "6602549178",
        },
      ],
      cta: ["IDs istraživača", "Kontakt"],
    },
  },
}
