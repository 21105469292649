import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/page-title"
import ContactsList from "../components/Contact/contacts-list"
import Gmap from "../components/Contact/g-map"
import Institutes from "../components/Contact/institutes"
import { pageContentContact } from "../markdown-text/content"
import { useStateValue } from "../contexts/state-context-provider"

const ContactPage = () => {
  const { language } = useStateValue()
  return (
    <Layout>
      <SEO title="Contact" />
      <PageTitle hTitle={pageContentContact[language].h} />
      <ContactsList
        contacts={pageContentContact[language].contacts}
        markdown={pageContentContact[language]}
      />
      <Gmap />
      <Institutes inst={pageContentContact[language].institutes} />
    </Layout>
  )
}

export default ContactPage
